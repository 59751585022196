import React from "react"

import links from "./content"

import * as S from "./styled"

const MenuLinks = () => (
  <S.MenuLinksWrapper>
    <ul>
      {
        links.map((link, i) => (
          <li key={i}>
            {
              (link.external)
              ? (
                  <a href={link.url}>{link.label}</a>
                )
              :
                (
                  <S.MenuLinksLink
                    to={link.url}
                    activeClassName="active"
                    >
                    {link.label}
                  </S.MenuLinksLink>
                )
            }
          </li>
        ))
      }
    </ul>
  </S.MenuLinksWrapper>
)

export default MenuLinks
