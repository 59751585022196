import React from 'react'

import SocialLinks from '../SocialLinks'
import * as S from './styled'

const FooterBar = () => (
  <S.FooterBarWrapper>
    <SocialLinks />
  </S.FooterBarWrapper>
)


export default FooterBar