import styled from "styled-components"
import media from "styled-media-query"
import * as V from '../../styles/variables'

export const MenuBarWrapper = styled.header`
  background: ${V.Color.grayDarker};
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  border-bottom: ${V.Border.box};
  box-shadow: ${V.BoxShadow.box};

  a {
    color: ${V.Color.grayWhite};
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &.site-name {
      font-size: 1.6em;
      font-weight: 800;
    }

    div {
      margin-right: ${V.Space.default};
    }
  }
`

export const MenuBarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  width: ${V.MaxSize.lg};
  margin : auto;

  ${media.lessThan("large")`
    width: 90vw;
  `}
`
