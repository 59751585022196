import styled from "styled-components"
import media from "styled-media-query"
import * as V from "../../styles/variables"

export const LayoutWrapper = styled.section`
  display: flex;
  overflow: hidden;

  &.link-na-bio {
    section:last-child {
      display: none;
    }
    header {
      h1 {
        margin-bottom: 0;
      }

      time, div > a {
        display:none;
      }
    }

    section {
      padding-top: 5px;
      h2 {
        margin: 10px 0;
      }
      p {
        font-size: 0.85em;
        margin-bottom : 0;
      }
      ul {
        padding: 0 ;
        margin : 0;
        li {
          list-style: none;
          font-size: 0.9rem;
          padding-top: 0;
          a {
            background: ${V.Color.yellow};
            color: ${V.Color.grayDarker};
            border : 1px solid ${V.Color.yellow};
            border-radius: ${V.BorderRadius.box};
            font-size : 1.1rem;
            display : block;
            padding : ${V.Space.xs};
          }
        }
      }
    }
  }

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`

export const LayoutMain = styled.main`
  background: ${V.Color.whiteSolid};
  min-height: 100vh;
  padding: ${V.Space.default} 0;
  margin : ${V.Space.default} auto;
  width: ${V.MaxSize.md};

  ${media.lessThan("medium")`
    width: 85vw;
  `}
`