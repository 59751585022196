import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled"
import GlobalStyles from "../../styles/global"
import MenuBar from '../MenuBar'
import FooterBar from '../FooterBar'

const Layout = ({ slug='default', insideIframe=false, children }) => {
  const slugWithoutSlashes = slug && slug.replace(/\//g,"");
  return (
    <>
      <GlobalStyles />
      {
        insideIframe 
        ? <>{children}</>

        : (
          <>
            <MenuBar />
            <S.LayoutWrapper className={slugWithoutSlashes}>
              <S.LayoutMain>{children}</S.LayoutMain>
              <FooterBar />
            </S.LayoutWrapper></>)}
          </>
        )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
