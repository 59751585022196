import styled from 'styled-components'

export const FooterBarWrapper = styled.footer`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 65px;
  background: var(--mediumBackground);
  border-top: 1px solid #ddd;
`