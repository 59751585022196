import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const SiteName = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    query MySiteName {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {title}
    </>
  )
}

export default SiteName
