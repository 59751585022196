import React from "react"

import SiteName from "../SiteName"
import MenuLinks from '../MenuLinks';

import { Link } from "gatsby"


import getThemeColor from "../../utils/getThemeColor"

import * as S from "./styled"

const MenuBar = () => {

  return (
    <S.MenuBarWrapper>
      <S.MenuBarContainer>
        <Link
          to="/"
          cover
          bg={getThemeColor()}
          className="site-name"
          title="Voltar para Home">
          <SiteName />
        </Link>
        <MenuLinks />
      </S.MenuBarContainer>
    </S.MenuBarWrapper>
  )
}

export default MenuBar
