import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"


export const MenuLinksWrapper = styled.nav`
  ${media.lessThan("large")`
    font-size: 16px;
  `}

  ul {
    display: flex;
    justify-content: space-between;

    li {
      padding: 1.2rem 0.5rem;
      .active {
        color: var(--highlight);
      }
    }
  }
`

export const MenuLinksLink = styled(Link)`
  color: var(--texts);
  display: none;
  text-decoration: none;
  transition: color 0.5s;

  &:hover {
    color: var(--highlight);
  }
`
